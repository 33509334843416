<template>
  <div>
    <v-form>
      <p class="mb-5">
        In the presence of concentrated sulfuric acid (catalyst), 1-bromobutane will undergo a
        dehydrohalogenation reaction resulting in the formation of an alkene.
      </p>

      <p class="mb-n3">a) 1-bromobutane would be classified as what type of haloalkane?</p>
      <v-radio-group v-model="inputs.studentAnswerA" :disabled="!allowEditing" class="mb-0 pl-6">
        <v-radio
          v-for="option in optionsA"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">b) What type of mechanism will this reaction follow?</p>
      <v-radio-group v-model="inputs.studentAnswerB" :disabled="!allowEditing" class="mb-0 pl-6">
        <v-radio
          v-for="option in optionsBShuffled"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI51LBA3_Q7',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswerA: null,
        studentAnswerB: null,
      },
      optionsA: [
        {
          text: 'Primary',
          value: 'primary',
        },
        {
          text: 'Secondary',
          value: 'secondary',
        },
        {
          text: 'Tertiary',
          value: 'tertiary',
        },
      ],
      optionsB: [
        {
          text: 'E1 only',
          value: 'e1',
        },
        {
          text: 'E1 and E2 combined',
          value: 'e1e2',
        },
        {
          text: 'E2 only',
          value: 'e2',
        },
        {
          text: '$\\text{S}_\\text{N}1$ only',
          value: 'SN1',
        },
        {
          text: '$\\text{S}_\\text{N}2$ only',
          value: 'SN2',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsBShuffled() {
      return seededShuffle(this.optionsB, this.seed);
    },
  },
};
</script>
